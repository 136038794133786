import "./index.css"
import Image from "../../assets/images/Vladutz.jpg"

const AboutUsPage = () => {
    return(
        <div className="about-us-page">
            <div className="about-us-uprow">
                <div className="about-us-title">ABOUT US</div>
                <div className="about-us-uprow-column">
                    <img className="about-us-uprow-image shadow" src={Image}/>
                    <div className="about-us-uprow-column-textbox">
                        <div className="about-us-subtitles">Our Roster</div>
                        <div className="about-us-uprow-column-textbox-text">Los Bandoleros is a booking and impresario agency based in Romania.Our team is made up of xperienced artists and professional DJs with experience in the music industry and succesful events.
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-us-row">
                <img className="about-us-row-image shadow" src="https://res.cloudinary.com/dqzkxvoc5/image/upload/v1732633722/449130646_18445404985034520_3377597745101225234_n_paw3bt.jpg"/>
                <div className="about-us-row-textbox">
                    <div className="about-us-subtitles">CINE SUNTEM</div>
                    <div className="about-us-row-textbox-text">Los Bandoleros nu doar că mixează muzică , dar și cuceresc inimile oamenilor din fiecare oraș pe care îl vizitează . Au o energie molipsitoare, o pasiune care nu poate fi ignorată și, mai presus de toate, o legătură cu publicul care îi fac să fie mai mult decât DJ — îi transformă în creatori de amintiri.
                    </div>
                </div>
            </div>
            <div className="about-us-last-textbox">
                <div className="about-us-subtitles">AGENTIA NOASTRA</div>
                <div className="about-us-last-textbox-text">Într-un oraș plin de muzică și lumină, există o echipă de artiști care aduc vibrații unice și atmosferă incendiară oriunde merg. Aceștia sunt cunoscuți sub numele de Los Bandoleros, un grup de DJ pasionați, unii ar zice chiar rebeli, care s-au adunat din colțuri diferite ale lumii pentru a crea o experiență muzicală imposibil de uitat.</div>
            </div>
            <img className="about-us-last-image" src="https://res.cloudinary.com/dqzkxvoc5/image/upload/v1732633289/186551625_167705202025161_5083838171156087269_n_ndcvqi.jpg"/>
        </div>
    )
}

export default  AboutUsPage