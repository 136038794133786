import "./index.css"
import Video1 from "../../assets/videos/main.mov"
import Video2 from "../../assets/videos/main2.mp4"
import Image1 from "../../assets/images/Kaisser.jpg"
import Image2 from "../../assets/images/alexandrescu.jpg"
import Image3 from "../../assets/images/Giani.jpg"
import Image4 from "../../assets/images/SirAlex.jpg"
import Image5 from "../../assets/images/Governor.jpg"
import Image6 from "../../assets/images/SirAlex2.jpg"
import Image7 from "../../assets/images/Pol.jpg"
import Skull from "../../assets/images/skull.png"
import Speaker from "../../assets/images/speakers.png"
import Logo from "../../assets/logo.png"
import 'animate.css';

const Hero = () => {

    return(
        <div className="hero-container">
            <div className="hero-images-container" >
                <img className="siralex-hero" src="https://res.cloudinary.com/dqzkxvoc5/image/upload/v1734551577/alexandrescu_pyz2jg.jpg" />
                <img className="hero-image big-hero-image kaisser-hero" src="https://res.cloudinary.com/dqzkxvoc5/image/upload/v1734551577/Kaisser_kzgwwj.jpg" />
                <img className="gioko-hero" src="https://res.cloudinary.com/dqzkxvoc5/image/upload/v1734551575/Giani_naukvr.jpg" />
                <img className="alexandrescu-hero" src="https://res.cloudinary.com/dqzkxvoc5/image/upload/v1734551576/SirAlex2_niitnl.jpg" />
                <img className="vladutz-hero" src="https://res.cloudinary.com/dqzkxvoc5/image/upload/v1734551577/Pol_vuaebx.jpg" />
            </div>
            <div className="hero-cover"></div>
            <div className="hero-skull-container"><img src={Skull} className="skull-image  animate__animated animate__fadeInDown"/></div>
            {/*<div className="left-speaker-container"><img src={Speaker} className="speaker-image left-speaker animate__animated animate__fadeInLeft"/></div>
            <div className="right-speaker-container"><img src={Speaker} className="speaker-image right-speaker animate__animated animate__fadeInLeft"/></div>*/}
            {/*<div className="left-hero-text-container"><div className="hero-text animate__animated animate__fadeInLeft">WELCOME</div></div>
            <div className="right-hero-text-container"><div className="hero-text animate__animated animate__fadeInRight">WELCOME</div></div>*/}
            {/*<div className="hero-logo-container"><img src={Logo} className="hero-logo animate__animated animate__fadeInUp"/></div>*/}
        </div>
    )
}

export default Hero