import "./index.css"
import {useEffect, useRef, useState} from "react"
import axios from "axios"
import useAuthContext from "../../react-logic/hooks/useAuthContext"
import { Navigate, useNavigate } from "react-router-dom"
import useArtistsContext from "../../react-logic/hooks/useArtistsContext"

const CreateArtist = () => {
    const nameRef = useRef(null)
    const descriptionRef = useRef(null)
    const imagesRef = useRef(null)
    const tiktokRef = useRef(null)
    const facebookRef = useRef(null)
    const youtubeRef = useRef(null)
    const instagramRef = useRef(null);
    const websiteRef = useRef(null);
    const bandcampRef = useRef(null);
    const beatportRef = useRef(null);
    const spotifyRef = useRef(null);
    const soundcloudRef = useRef(null);
    const navigate = useNavigate();
    const {dispatch} = useArtistsContext()
    const {token} = useAuthContext()
    const [validate,setValidate] = useState(false);

    const handleSubmit = async() => {
        const artist = new FormData();
        artist.append('name',nameRef.current.value)
        artist.append('description',descriptionRef.current.value)
        artist.append('instagram',instagramRef.current.value)
        artist.append('facebook',facebookRef.current.value)
        artist.append('tiktok',tiktokRef.current.value)
        artist.append('youtube',youtubeRef.current.value)
        artist.append('website',websiteRef.current.value)
        artist.append('beatport',beatportRef.current.value)
        artist.append('bandcamp',bandcampRef.current.value)
        artist.append('soundcloud',soundcloudRef.current.value)
        artist.append('spotify',spotifyRef.current.value)
        const uploaded_artist = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/artists`,artist,{headers:{
            'Content-Type': 'application/json',
            }})
        const images = new FormData();
        for(const img of imagesRef.current.files){
            images.append('images',img);
        }
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/artists/${uploaded_artist.data._id}/images`,images,{
            headers:{
                'Content-type':'multipart/form-data'
            }
        })
        const finished_artist = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/artists/${uploaded_artist.data._id}`)
        dispatch({type:"ADD_ARTIST",payload:finished_artist.data})
        navigate(`/artist/${uploaded_artist.data._id}`)
    }


    return(
        <div className="artist-page">
            <h3 className="tab-title">Create Artist</h3>
                <input ref={imagesRef} className="input-image-button" type="file" accept="image/*" multiple />
                <input ref={nameRef} type="text" placeholder="Artist Name*" className="create-product-input create-name"/>
                <textarea ref={descriptionRef} placeholder="Description*" className="create-product-input create-description"/>
                <input ref={instagramRef} type="text" placeholder="Instagram" className="create-product-input create-name"/>
                <input ref={facebookRef} type="text" placeholder="Facebook" className="create-product-input create-name"/>
                <input ref={tiktokRef} type="text" placeholder="Tiktok" className="create-product-input create-name"/>
                <input ref={youtubeRef} type="text" placeholder="Youtube" className="create-product-input create-name"/>
                <input ref={websiteRef} type="text" placeholder="Website" className="create-product-input create-name"/>
                <input ref={beatportRef} type="text" placeholder="Beatport Link" className="create-product-input create-name"/>
                <input ref={bandcampRef} type="text" placeholder="Bandcamp Link" className="create-product-input create-name"/>
                <input ref={soundcloudRef} type="text" placeholder="Soundcloud Link" className="create-product-input create-name"/>
                <input ref={spotifyRef} type="text" placeholder="Spotify Link" className="create-product-input create-name"/>
                <div className="create-product-submit-container">
                    <div onClick={() => handleSubmit()} className="create-product-submit-button pointer">Create</div>
                </div>
        </div>
    )
}

export default CreateArtist;