import "./index.css"
import {useState,useRef} from "react"
import axios from "axios"
import useAuthContext from "../../react-logic/hooks/useAuthContext"
import { Link, Navigate, useNavigate } from "react-router-dom"

const LoginPage = () => {
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const {token,setToken,setUser} = useAuthContext();
    const navigate = useNavigate();

    const handleSend = () => {
            const user = {
                email:emailRef.current.value,
                password:passwordRef.current.value,
            }
            console.log(user)
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/login`,user,{
                headers:{
                    "Content-type":"application/json"
                }
            })
            .then((result) => {
                setToken(result.data.accessToken);
                setUser(result.data);
                navigate("/create-artist");
            })
            .catch((error) => {
                console.log(error.request.response);
            })
    }

    return(
        !token ? <div className="login-page">
            <div className="login-container shadow">
                <h2 className="login-title">Welcome back!</h2>
                <div className="space"></div>
                {/* REMEMBER TO ADD VALIDATOR */}
                <input type="email" ref={emailRef} placeholder="EMAIL*" className="input email "/>
                <input type="password" ref={passwordRef} placeholder="PASSWORD*" className="input email "/>
                <div className="login-button pointer" onClick={() => handleSend()}>LOG IN</div>
            </div>
        </div>
        :
        <Navigate to="/create-artist" />
    )
}

export default LoginPage;