import {createContext,useEffect,useState,useReducer} from "react"
import axios from "axios"
import useAuthContext from "../hooks/useAuthContext";

export const ArtistsContext = createContext();

export const ArtistsReducer = (state,action) => {
    switch(action.type){
        case 'SET_ARTISTS':
            return {
                artists:action.payload
            }
        case 'ADD_ARTIST':
            return {
                artists:[...state.artists,action.payload]
            }
        case 'DELETE_ARTIST':
            return{
                artists:state.artists.filter((product) => product._id != action.payload)
            }
        default:
            return state
    }
}

const ArtistsProvider = ({children}) => {
   const [error,setError] = useState(false);
   const [isLoading,setIsLoading] = useState(false);
   const [totalProducts, setTotalProducts] = useState(0);
   const [totalPages, setTotalPages] = useState(0);
   const [currentPage,setCurrentPage] = useState(0);
   const {user} = useAuthContext();
    const [state,dispatch] = useReducer(ArtistsReducer,{
        artists:[,{
            name:"Alexandrescu",
            description:"Alexandrescu is a Romanian DJ, founder of Rockstar Collective and mastermind behind the Burning Man camp Vampire Empire. Known for his for his euphoric sets that meld vibrant rhythms with the soul of house music, he captured the hearts of his audience. His sets are not just performances, they are gatherings of kindred spirits united under the banner of profound beats and electrifying melodies.",
            image:"https://res.cloudinary.com/dqzkxvoc5/image/upload/v1734551577/alexandrescu_pyz2jg.jpg",
            soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1629869115&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
            socialMedia:[{
                name:"instagram",
                link:"https://www.instagram.com/alexandrescu667/"
            },{
                name:"facebook",
                link:"https://www.facebook.com/alexandrescu667"
            },{
                name:"tiktok",
                link:"https://www.tiktok.com/@alexandrescu667?_t=8n8a11i7QfY&_r=1"
            }],
            discography:[],
            id:15
        },
        {
            name:"KAISSER",
            description:"'Just a strange guy from the mountains' he says. But this is a merely description that reveal us just a small part of his origins and his spirit. In a short history, his real passion for music, was planted at a young age by his elder brother, when he received a recorded cassette tape of Sepultura. Since then, he attended every rock concert until 1995. In the early 2000s his passion for 'Rage Against The Machine', 'Beastie Boys' & 'Die Krupps' paved the road to what will become a wide collection of 'FUTURE Sound of London', 'Orbital', 'The Orb' & 'Underworld'. Which will set him on the path of electronic music. Always present in the first electronic music clubs opened Bucharest like Studio Martin, Web Club, Karma and Zerillos. In 2001 he was driven to buy his first turntables and went from being a fan of music to a passionate, real Dj with the help of Dj Kool and Dj Demos, and since those times, his passion for electronic music evolved to tech house, deep house, micro house and techno. To which he remained faithful. Now, his productions and sets are a strange genuine voyage into his own cosmic universe, where that huge void is in fact filled with stars transposed into dark sounds, and every planet is a combination between his music culture, passion and hard work. Always drawn by atypical textures, he manages to 'vocalize' his own experiences by leading his crowd into his void. Somehow, all of that combined, resulting into an uplifting state of mind. His adventurous spirit transcends into the sets he plays, where we can make an ambitious comparison with the Haydn's 'Surprise', introducing all kinds of unlikely elements such as old-school drum and bass or classical music. Throughout his career he played along: Keny Larkin, John Creamer, Art Department, Ellen Allien, Francesca Lombardo, Monika Kruse, Nicolas Lutz, Christian Burkhardt, Steve Lawler, Livio and Roby, kozo, Sasha Dive, Audiofly, lo Mullen, Ion Ludwig and many more.",
            image:"https://res.cloudinary.com/dqzkxvoc5/image/upload/v1734551577/Kaisser_kzgwwj.jpg",
            soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/595223718&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
            socialMedia:[{
                name:"instagram",
                link:"https://www.instagram.com/kaisser.music/"
            },{
                name:"facebook",
                link:"https://www.facebook.com/profile.php?id=61555082482120"
            },{
                name:"youtube",
                link:"https://www.youtube.com/channel/UCYAkY21n78EJnbmbHK7bCdQ"
            }],
            discography:[],
            id:16
        },
        {
            name:"Gioko",
            description:"Gioko is a romanian DJ and producer. His passion stared in 2017,and since then he invested a lot of time and  effort in developing his mixing skills, experimenting with different styles and sounds to create unique and captivating mixes.",
            image:"https://res.cloudinary.com/dqzkxvoc5/image/upload/v1734551575/Giani_naukvr.jpg",
            soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1809665412&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
            socialMedia:[{
                name:"instagram",
                link:"https://www.instagram.com/gioko__/"
            },{
                name:"facebook",
                link:"https://www.facebook.com/Giokodj"
            },{
                name:"youtube",
                link:"https://www.youtube.com/watch?v=OFIvnYjNSNw"
            }],
            discography:[],
            id:17
        },
        {
            name:"PoL",
            id:19,
            description:"PoL, by his real name Paul Vasilescu was born in the heart of Bucharest – Romania on 11-06-1986 and still, his heart is where he mixes, his soul is for the people. His curiosity about music began in a morning when he attended his first after hour party at a very well-known club in Bucharest named Silver then arrived home and started to look for software that could help him understand how it’s done and self-learned the basics. After building his character he started putting his feelings in the music he played slowly improving his technical abilities and favorite music genre. He began his DJ career in 2010 and in 2012 he became DJ resident at FlyBoard Snagov. In the same year he became Resident DJ at Pop Bar by Van Gogh. 2015 was the year when he started the residency at MooD Snagov and Sankt Petersburg Pub where he hosted a party every monday for about two years. 2016 is the year of new opportunities and besides the existing residencies he is hosting Tech Whispers, a weekly live show at Radio DEEA and a new residency at Privilege Sound party hosted by Jack’s Pub. Starting 2017 he is part of We Are Golan project as one of the resident djs and event manager. His favorite genres are very diverse from funky house, nu-disco to deep-tech, techno, progresive and also minimal and micro, he played together with well-known local DJs like: Negru, Adrian Eftimie, Optick, Rosario Internullo, Victor Stancov, Marco Briguglia, Just2, Dobrikan, Dubphone, Luca M, Marika, Andre Rizo, Bibi, Gully, Diego, Double Deez, Alex Dutu, Alex T, The Gang, Christian Lepah, No Parachute also he had the opportunity to play together with international DJs like SIS, Ninetoes, Jose Maria Ramon, Neverdogs,Jerry Ropero and Horatio, HVMZA, FNX Omar. His motto is: “You can find bits and pieces of your soul in the music I play”. His favorite quote: “Life without music would be a mistake” (Fredrick Nietzsche).",
            image:"https://res.cloudinary.com/dqzkxvoc5/image/upload/v1734551577/Pol_vuaebx.jpg",
            socialMedia:[{
                name:"instagram",
                link:"https://www.instagram.com/pol.official_page"
            },{
                name:"facebook",
                link:"https://www.facebook.com/PoL.Official.Page"
            }],
            discography:[],
        },{
            name:"Alexander Fabyann",
            id:22,
            description:"Coming from Romania, Alexander Fabyann has been active in the electronic music since the age of 20, gathering many fans and appreciation for his work. He travelled for some time to Spain and Greece, through the island of Rhodes, Crete and Palma de Mallorca, places that helped him gain experience and cultural diversity. Alexander Fabyann has been confirmed at many festivals, such as SAGA Festival, BEACH, PLEASE Festival, HUSTLE Festival (Soho Stage), DEEP FOREST FEST, SUMMER SPELL, and other promising ongoing projects(Kristal Club, Laminor Arena, Monteoru, nJoy Club, JELECTRO in London) on stage with renowed artists. Additionally, in the summer of 2023 he undertook his residency at Therme Bucharest. He approaches tech-house and techno in his sets, managing to capture beats and the overflowing energy inspired by the audience. For him, things can go only uphill, 'under the influence of good music'. ",
            image:"https://res.cloudinary.com/dqzkxvoc5/image/upload/v1734641355/DSC08964_ujcqxh.jpg",
            soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1842982719&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
            socialMedia:[{
                name:"instagram",
                link:"https://www.instagram.com/alexanderfabyann?igsh=aTg1dWQ1YnJ2c2Y0"
            },{
                name:"facebook",
                link:"https://www.facebook.com/alexander.fabyann"
            },{
                name:"tiktok",
                link:"https://www.tiktok.com/@alexanderfabyann?_t=8okjgfC04Qb&_r=1"
            }],
            discography:[],
        },{
            name:"Vladutz",
            image:"https://res.cloudinary.com/dqzkxvoc5/image/upload/v1734641271/DSC054431_nm6bji.jpg",
            description:"Vladutz is a young promise DJ from Romania discovered by us. His passion started in 2016 and since then he has been spinning records focusing on electronic music styles reinterpreted by the Romanian (ro-minimal) groove, recently combining minimal tech with Romanian percussion and voices, becoming a resident for BABYLON Events.",
            socialMedia:[{
                name:"instagram",
                link:"https://www.instagram.com/1vladutz_/"
            }],
            discography:[],
            id:9
        },{
            name:"Sir Alex",
            description:"Sir Alex is part of the new generation of romanian Djs,rising through the music scene with his high energy grooves and explosive DJ sets.Being one of residents at BABYLON events,he developed his own distinctive sound based out of his influences and passions, thing that has made him a favorite among fans. He played music in several places,suchas Kristal Club, Gradina Monteoru, Shade Events, Love Life Events, Rave Timisoara, High Waves Sounds Cluj, Gatsby Timisoara, ilCharlie Iasi and much more. He has delighted us throughout his career with songs loved by his fans, songs like “Ala ala ala“, “MiddleChild“, “Pure honey“ and many others.", 
            image:"https://res.cloudinary.com/dqzkxvoc5/image/upload/v1734551576/SirAlex2_niitnl.jpg",
            soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1818131970&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
            socialMedia:[{
                name:"instagram",
                link:"https://www.instagram.com/sir____alex____/"
            },{
                name:"tiktok",
                link:"https://www.tiktok.com/@siralex151"
            }],
            discography:[],
            id: 1
        },]
    })

    const getArtists = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/artists/all`,{
                params:{
                    page:currentPage,
                    archived:false,
                }
            })
            dispatch({type:"SET_ARTISTS",payload:response.data})
        }catch(err){
            console.log(err)
        }
    }

    const getArtist = (id) => {
        const artist = state.artists.filter(artist => artist.id == id)
        return artist[0]
    }

    useEffect(() => {
        setIsLoading(true);
        (async () => await getArtists())()
        setIsLoading(false)
    },[currentPage])

    return(
        <ArtistsContext.Provider value={{artists:state.artists,dispatch,isLoading,error,totalProducts,totalPages,setCurrentPage,currentPage,getArtist}}>
            {children}
        </ArtistsContext.Provider>
    )
}

export default ArtistsProvider;
