import React, { useRef } from "react";
import Hero from "../../components/hero";
import useArtistsContext from "../../react-logic/hooks/useArtistsContext";
import "./index.css"
import "react-multi-carousel/lib/styles.css";
import useEmblaCarousel from 'embla-carousel-react'
import AutoScroll from 'embla-carousel-auto-scroll'
import {useNavigate} from "react-router-dom"
import {faInstagram, faTiktok, faYoutube} from "@fortawesome/free-brands-svg-icons"
import {faEnvelope} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Image from "../../assets/images/Governor.jpg"
import emailjs from "@emailjs/browser"

const Homepage = () => {
    const responsive = {
        superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
        desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
        tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
        mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
    };
    const form = useRef()
    const {artists} = useArtistsContext()
    console.log(artists)
    const options = { dragFree: true, loop: true, slidesToScroll: 'auto' };
    const [emblaRef, emblaApi] = useEmblaCarousel(options, [
        AutoScroll({ speed: .75, startDelay: 0})
      ])
      const navigate = useNavigate()

      const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_erga2dh","template_0q1ovvf",form.current,{publicKey:"2BelVwtzfzQdsWa3q"})
    }
        
    return(
        <div className="homepage-container">
            <Hero />
            <div className="about-us-section">
                <div className="about-us-container">
                    <div className="about-us-content">
                        <div className="about-us-home-title">Cine suntem!</div>
                        <div className="about-us-text">
                        Intr-un oras plin de muzica si lumina, exista o echipa de artisti care aduc vibratii unice si atmosfera incendiara oriunde merg. Acestia sunt cunoscuti sub numele de Los Bandoleros, un grup de DJ pasionati, unii ar zice chiar rebeli, care s-au adunat din colturi diferite ale lumii pentru a crea o experienta muzicala imposibil de uitat.
                        </div>
                    </div>
                    <img
                        src={Image}
                        className="about-us-image"
                    />
                </div>
            </div>

            <div className="carousel-section">
                <h2 className="carousel-title">Roster</h2>
                <section className="embla">
            <div className="embla__viewport" ref={emblaRef}>
              <div className="embla__container">
                {artists.map((artist,index) => (
                  <img src={artist.image} className="embla__slide" id={`carousel-${artist.name}`} key={index} onClick={() => navigate(`/artist/${artist.id}`)}>
                  </img>
                ))}
              </div>
            </div>
    </section>
            </div>

            {/* Contact Us Section */}
            <div className="contact-us-section" id="contact-form">
                <div className="contact-us-title-container">
                    <div className="infotext">
                        <div className="contact-us-home-title">Contact us</div>
                    </div>
                </div>
                <div className="socials-container">
                    <a target="_blank" className="small-social-container social-container" href="https://www.youtube.com/@LosBandolerosAgency" >
                        <FontAwesomeIcon icon={faYoutube} className="social-logo"/>
                        <p className="social-text">Youtube</p>
                    </a>
                    <a target="_blank" className="small-social-container social-container" href="https://www.instagram.com/losbandoleros.ro/" >
                        <FontAwesomeIcon icon={faInstagram} className="social-logo"/>
                        <p className="social-text">instagram</p>
                    </a>
                    <a target="_blank" className="small-social-container social-container" href="https://www.tiktok.com/@losbandoleros.ro" >
                        <FontAwesomeIcon icon={faTiktok} className="social-logo"/>
                        <p className="social-text">tiktok</p>
                    </a>
                    <a target="_blank" className="small-social-container social-container" href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@losbandoleros.ro" >
                        <FontAwesomeIcon icon={faEnvelope} className="social-logo"/>
                        <p className="social-text">email</p>
                    </a>
                </div>
                <form className="contact-form" ref={form} onSubmit={sendEmail}>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="from_name"
                            placeholder="Name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Numar de telefon</label>
                        <input
                            type="text"
                            id="name"
                            name="phone"
                            placeholder="Numar de telefon"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="my_email"
                            placeholder="Email"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            placeholder="Message"
                            rows="5"
                            required
                        ></textarea>
                    <input type="submit" className="contact-form-button"value="send" />
                    </div>
                </form>
            </div>
        </div>
    );
};
export default Homepage;
