import "./index.css"
//import Background from "../../assets/backgrounds/CreasedPaper02-byGhostlyPixels.png"
import { useState } from "react";
import useArtistsContext from "../../react-logic/hooks/useArtistsContext";
import { useNavigate } from "react-router-dom";
import Background from "../../assets/background/04.jpg"
//01 and 04, DS2 8 16 20 

const Roster = () => {
    const [currentHover,setCurrentHover] = useState(null);
    const {artists} = useArtistsContext();
    const navigate = useNavigate()
    console.log(artists)
    return(
        <div className="roster-page">
            <h3 className="roster-title">roster</h3>
            <div className="roster-container">
            <img src={Background} className="roster-background"/>
                    {artists && artists.map((artist,index) => (
                        <div className="artist-card"
                        key={index}
                        onClick={() => navigate(`/artist/${artist.id}`)}>
                            <div className="artist-card-inner">
                                {/* Front Face */}
                                <div className="flip-card-front">
                                  <img src={artist.image && artist.image} id={`roster-${artist.name.replace(" ","")}`}/>
                                </div>
                                {/* Back Face */}
                                <div className="flip-card-back">
                                  <p>{artist.name.toUpperCase()}</p>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}

{/*<div className="artist-card" key={index}>
    <img className="artist-image" src={artist.image}/>
    <div className="artist-name">{artist.name}</div>
</div>*/}

export default Roster;