import "./index.css"
import {useNavigate, useParams} from "react-router-dom"
import useArtistsContext from "../../react-logic/hooks/useArtistsContext"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faInstagram,faTiktok,faYoutube,faFacebook} from "@fortawesome/free-brands-svg-icons"
import {faGlobe} from "@fortawesome/free-solid-svg-icons"
const ArtistPage = () => {
    const socialIcons = {
        instagram:faInstagram,
        tiktok:faTiktok,
        youtube:faYoutube,
        facebook:faFacebook,
        website:faGlobe
    }
    const {id} = useParams();
    const {dispatch,getArtist} = useArtistsContext();
    const artist = getArtist(id)
    console.log(artist.socialMedia)
    const navigate = useNavigate();
    return(
        <div className="product-page">
            {artist && <div className="product-container">
                <div className="product-images-container">
                    {artist.image && <img src={artist.image} className="image-container" />}
                </div>
                <div className="product-title">{artist.name}</div>
                <div className="artist-info-container">
                    {artist.description && <div className="product-description">{artist.description}</div>}
                    <div className="artist-social-media">
                        {artist.socialMedia && artist.socialMedia?.map((social,index) => {
                            if(social.link){
                                return(
                                    <a className="artist-social-container" key={index} href={`http://${social?.link}`}>
                                        <FontAwesomeIcon icon={socialIcons[social?.name]} size="2x" color="white" className="social-icon"/>
                                        <div className="artist-social-name">{social?.name.toUpperCase()}</div>
                                    </a>
                                )
                            }})}
                    </div>
                </div>
                {artist.discography[0] && <div className="product-title">Discography</div>}
                <div className="artist-discography-container">
                        {artist.discography.map((element,index) => (
                            <div key={index}
                            className="artist-discography-element"
                            dangerouslySetInnerHTML={{__html:element.link}} />
                        ))}
                </div>
            </div>}
        </div>
    )
}

export default ArtistPage