import "./index.css"
import {Link, useNavigate} from "react-router-dom"
import Logo from "../../assets/logo.png"
import useAuthContext from "../../react-logic/hooks/useAuthContext"

const Navbar = () => {
    const navigate = useNavigate()
    const {user,logout} = useAuthContext()
    const routes = [
        {
            path:"/",
            display:"Home"
        },{
            path:"/roster",
            display:"Roster"
        },{
            path:"/about-us",
            display:"About Us"
        }
    ]
    return(
        <div className="navbar-container">
            {/*<img
            src={Logo}
            width={300}
            className="navbar-logo"
            onClick={() => navigate("/")}/>*/}
            <div className="navbar-empty" ></div>
            <div className="navbar-links-container">
                {routes.map((route,index) => (
                    <Link
                    to={route.path}
                    key={index}
                    className="navbar-link">{route.display.toUpperCase()}</Link>
                ))}
                {user?.isAdmin && 
                <>
                    <Link
                    to="/create-artist"
                    className="navbar-link">ADD ARTIST</Link>
                    <div className="navbar-link pointer" onClick={() => logout()} >LOGOUT</div>
                </>
                }
            </div>
        </div>
    )
}

export default Navbar