import "./index.css"
import Logo from "../../assets/logo.png"

const Footer = () => {
    const leftLinks = [
        {
            display:"Contact Us",
            link:"/#contact-form"
        },
        {
            display:"About us",
            link:"/about-us"
        },{
            display:"Roster",
            link:"/roster"
        },
        {
            display:"Home",
            link:"/"
        },
    ]
    const middleLinks = [
        {
            display:"Instagram",
            link:"https://www.instagram.com/losbandoleros.ro/"
        },{
            display:"Youtube",
            link:"https://www.youtube.com/@LosBandolerosAgency"
        },{
            display:"Tiktok",
            link:"https://www.tiktok.com/@losbandoleros.ro"
        },{
            display:"Email",
            link:"https://mail.google.com/mail/?view=cm&fs=1&to=contact@losbandoleros.ro"
        }
    ]
    return(
        <div className="footer-container">
            <div className="footer-subcontainer left-subcontainer">
                <div className="container-title">Pages</div>
                {leftLinks.map((link,index) => (
                    <a href={link.link} className="container-links" key={index}>{link.display}</a>
                ))}
            </div>
            <div className="footer-subcontainer middle-subcontainer">
                    <img src={Logo} className="container-logo" />
            </div>
            {/*<div className="footer-subcontainer right-subcontainer">
                <div className="container-title">Socials</div>
                {middleLinks.map((link,index) => (
                            <a href={link.link} className="container-links" key={index}>{link.display}</a>
                        ))}
            </div>*/}
            <div className="footer-copyright">
                Copyright 2024 @ Los Bandoleros Agency
            </div>
        </div>
    )
}

export default Footer;