import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./main.css"
import Homepage from "./pages/homepage"
import Roster from './pages/roster';
import LoginPage from './pages/login';
import AuthProvider from './react-logic/context/authContext';
import CreateArtist from './pages/create-artist';
import ArtistsProvider from './react-logic/context/artistsContext';
import ArtistPage from './pages/artist-page';
import AboutUsPage from './pages/about-us';

const router = createBrowserRouter([
  {
    path:"/",
    element:<App />,
    children:[
      {
        path:"/",
        element:<Homepage />
      },
      {
        path:"/roster",
        element:<Roster />
      },
      {
        path:"/login",
        element:<LoginPage />
      },
      {
        path:"/create-artist",
        element:<CreateArtist />
      },
      {
        path:"/artist/:id",
        element:<ArtistPage />
      },
      {
        path:"about-us",
        element:<AboutUsPage />
      }
    ]
  }
])  

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ArtistsProvider>
        <RouterProvider router={router} />
      </ArtistsProvider>
    </AuthProvider>
  </React.StrictMode>
);
